import { ValidatorFn, AbstractControl } from "@angular/forms";

export function maxLengthExcludingSpaces(max: number): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) return null; // No value means no validation error
        const lengthWithoutSpaces = control.value.replace(/\s/g, '').length;
        return lengthWithoutSpaces > max
            ? { maxLengthExcludingSpaces: { requiredLength: max, actualLength: lengthWithoutSpaces } }
            : null;
    };
}

export function lengthWithoutSpaces(value: string): number {
    if (!value) return 0; // Handle null or undefined inputs
    return value.replace(/\s/g, '').length;
}