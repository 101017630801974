<form *ngIf="visit" [formGroup]="cnForm" (ngSubmit)="onSubmit(cnForm)">

  <div class="input-container">
    <label class="mat-body">{{'MISC.DATE' | translate}}&nbsp;(mm/dd/yyyy)</label>
    <div class="date-input">
      <input id="date-input-edit-visit" autocomplete="off" formControlName="date"
        placeholder="{{'FORMS.CHOOSE DATE' | translate}}" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="validation-container" *ngIf="date?.invalid && (date?.dirty || date?.touched)">
      <span class="input-error" *ngIf="date.errors.required">{{'FORMS.VALID DATE' | translate}}</span>
    </div>
  </div>

  <div class="input-container">
    <label class="mat-body">{{'FORMS.SUMMARY' | translate}}</label>
    <textarea required formControlName="summary" [value]="visit.summary"
      placeholder="{{'FORMS.ENTER SUMMARY' | translate}}" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
    <div class="validation-container">
      <div class="character-count">
        <span>{{ calcLength(summary?.value) || 0 }}/255</span>
      </div>
      <span *ngIf="calcLength(summary?.value) > 255 && calcLength(summary?.value) <= 325" class="warning-text">Exceeded
        recommended length</span>
      <ng-container *ngIf="summary?.invalid && (summary?.dirty || summary?.touched)">
        <span *ngIf="summary?.errors?.['required']" class="input-error">Required</span>
        <span *ngIf="summary?.errors?.['maxLengthExcludingSpaces']" class="input-error">
          Exceeded maximum length</span>
      </ng-container>
    </div>
  </div>

  <div class="btn-container">
    <div>
      <button mat-stroked-button [color]="cnForm.valid ? 'accent' : null" type="submit" [class.mat-flat-button]="cnForm.valid" [disabled]="!cnForm.valid" aria-label="Save visit">
        {{'ACTIONS.SAVE' | translate}}
      </button>
      <button mat-button type="button" (click)="closeEditor()">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
    </div>
  </div>

</form>