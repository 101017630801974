<app-message-info *ngIf="showMessage" content="{{'INFO.VISITS' | translate}}"></app-message-info>
<form [formGroup]="visitForm" (ngSubmit)="onSubmit(visitForm, $event)" class="form-container" autocomplete="off">
  <div class="input-container date-container">
    <label class="mat-body">{{'MISC.DATE' | translate}}&nbsp;(mm/dd/yyyy)</label>
    <input #dateInput required formControlName="dateValue" [matDatepicker]="picker" placeholder="{{'FORMS.CHOOSE DATE' | translate}}">
    <div class="datepick">
      <mat-datepicker-toggle disableRipple matSuffix [for]="picker">
        <mat-icon class="picker-icon" matDatepickerToggleIcon>
          calendar_month
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <ng-container *ngIf="dateValue?.invalid && dateValue?.dirty">
      <div class="input-error" *ngIf="dateValue.errors.required">{{'FORMS.VALID DATE' | translate}}</div>
    </ng-container>
  </div>

  <div class="input-container summary">
    <label class="mat-body">{{'FORMS.SUMMARY' | translate}}</label>
    <textarea rows="4" required formControlName="summaryValue" placeholder="{{'FORMS.ENTER SUMMARY' | translate}}"></textarea>

    <span class="mat-small">
      {{ calcLength(summaryValue?.value) || 0 }}/255
      <span *ngIf="calcLength(summaryValue?.value) > 255 && calcLength(summaryValue?.value) <= 325" class="warning-text">
        Exceeded recommended length
      </span>
      <ng-container *ngIf="summaryValue?.invalid && (summaryValue?.dirty || summaryValue?.touched)">
        <span *ngIf="summaryValue?.errors?.['required']" class="input-error">Required</span>
        <span *ngIf="summaryValue?.errors?.['maxLengthExcludingSpaces']" class="input-error">Exceeded maximum length</span>
      </ng-container>
    </span>
  </div>

  <button type="submit" [disabled]="!visitForm.valid" aria-label="Add visit" mat-stroked-button [class.mat-flat-button]="visitForm.valid" [color]="visitForm.valid ? 'accent' : null">
    <mat-icon class="all-valid">check_circle_outline</mat-icon>&nbsp;
      <span class="mat-body">{{'ACTIONS.SAVE' | translate}}</span>
  </button>
</form>