import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { CustomDateAdapterService } from 'src/app/custom-date-adapter.service';
import { AllVisitsDocument, CreateVisitGQL } from '../../../api/generated/graphql';
import { maxLengthExcludingSpaces, lengthWithoutSpaces } from 'src/app/utils/extractSpacesValidator';

declare let gtag: Function;

@Component({
  selector: 'app-new-visit',
  templateUrl: './new-visit.component.html',
  styleUrls: ['./new-visit.component.css']
})

export class NewVisitComponent implements OnInit {

  @ViewChild('dateInput', { read: ElementRef })
  dateInput!: ElementRef;

  loading: boolean = true;
  error: any;
  @Input() reportID: string | null = null;
  @Input() showMessage: boolean = true;
  visitForm!: FormGroup;
  calcLength = lengthWithoutSpaces;

  constructor(
    private dateAdapter: CustomDateAdapterService,
    private createVisitGQL: CreateVisitGQL,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.visitForm = new FormGroup({
      dateValue: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
      summaryValue: new FormControl('', { validators: [Validators.required, maxLengthExcludingSpaces(325)], updateOn: 'change' }),
    });
  }

  ngOnInit() {}

  onSubmit(form: FormGroup, event: { currentTarget: { reset: () => void; }; }) {

    form.markAllAsTouched

    let valid = this.validator(form)

    if (valid) {
      this.create(form)
      // the next two lines are a workaround because since we're
      //using the same form (not rebuilding the view) there isn't
      // any clean way to reset validation and values
      event.currentTarget.reset()
      form.reset()
      this.dateInput.nativeElement.focus()
    }
  }

  validator(form: FormGroup) {
    if (form.invalid) {
      return false;
    }

    return true;
  }

  create(form: FormGroup) {

    let d = this.dateAdapter.format(form.controls.dateValue.value, "input");
    if (this.reportID) {
      this.createVisitGQL
        .mutate({
          date: d,
          summary: form.controls.summaryValue.value,
          reportID: this.reportID
        },
        {
          refetchQueries: [{ query: AllVisitsDocument, variables: { id: this.reportID } }]
        })
        .subscribe({
          error: err => {
            this.error = err;
            console.error(err);
          },
          complete: () => {
            this.onSuccess();
            this.sendEvent();
          }
        });
    }
  }

  onSuccess() {

    this.translate.get(['SNACKS.VISIT CREATED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.VISIT CREATED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })
  }

  get summaryValue() { return this.visitForm.get('summaryValue'); }

  get dateValue() { return this.visitForm.get('dateValue'); }

  sendEvent(): void {
    gtag('event', 'create visit', {
      'event_category': 'db-create',
      'event_label': 'method'
    });
  }
  
}