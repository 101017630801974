import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AllVisitsDocument, UpdateVisitGQL, Visit } from 'src/app/api/generated/graphql';
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { maxLengthExcludingSpaces, lengthWithoutSpaces } from 'src/app/utils/extractSpacesValidator';

declare let gtag: Function;

@Component({
  selector: 'app-edit-visit',
  templateUrl: './edit-visit.component.html',
  styleUrls: ['./edit-visit.component.css']
})
export class EditVisitComponent implements OnInit {

  loading: boolean = true;
  error: any;
  cnForm: any;
  pipedDate: any;
  @Input()
  visit!: Visit;
  @Input()
  reportId!: string;
  @Output() closeDialog = new EventEmitter();

  @ViewChild('dateInput', { read: ElementRef })
  dateInput!: ElementRef;
  calcLength = lengthWithoutSpaces;

  constructor(
    private updateVisitGQL: UpdateVisitGQL,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if (this.visit?.date) {
      this.cnForm = new FormGroup({
        date: new FormControl(this.visit.date, { validators: [Validators.required], updateOn: 'submit' }),
        summary: new FormControl(this.visit.summary, { validators: [Validators.required, maxLengthExcludingSpaces(325)], updateOn: 'change'})
      })
    }
  }
  
  closeEditor() {
    this.closeDialog.emit()
  }

  onSubmit(form: FormGroup) {
    let valid = this.validator(form)

    if (valid) {
      this.update(form)
    }
  }

  validator(form: FormGroup) {
    if (form.invalid) {
      return false;
    }

    return true;
  }

  update(form: FormGroup) {
    this.updateVisitGQL
      .mutate({
        id: this.visit.id,
        reportID: this.reportId,
        date: form.controls.date.value,
        summary: form.controls.summary.value.trim(),
      }, {
        refetchQueries: [{ query: AllVisitsDocument, variables: { id: this.reportId } }]
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: () => this.onSuccess()
      });

    this.closeEditor()
  }

  onSuccess() {

    this.translate.get(['SNACKS.VISIT UPDATED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {

      this._snackBar.open(res['SNACKS.VISIT UPDATED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })
  }

  sendEvent(): void {
    gtag('event', 'edit_visit', {
      'event_category': 'db-update',
      'event_label': 'method'
    });
  }

  get date() { return this.cnForm.get('date') }

  get summary() { return this.cnForm.get('summary') }

}